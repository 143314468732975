<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="message-header" slot="header">
      <p class="message-header-title">{{ messageData.title }}</p>
    </div>
    <div class="message-component" slot="component">
      <p class="message-component-data word-break">{{ messageData.message }}</p>
    </div>
  </Popup>
</template>

<script>
export default {
  name: "message",
  props: {
    closePopup: {
      type: Function,
    },
    messageData: {
      type: Object,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "20% auto",
      radiusType: "full",
    };
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.message-header {
  padding: 1.2rem;
  text-align: center;
  border-bottom: 1px solid black;
  &-title {
    color: #ffffff;
    font-family: $font-regular;
    font-weight: bold;
  }
}
.message-component {
  padding: 1.2rem;
  text-align: center;
  &-data {
    color: #ffffff;
    font-family: $font-regular;
    word-break: break-all;
    line-height: 1.5rem;
  }

  .word-break {
    word-break: keep-all;
  }
}
</style>
